import * as React from "react"
import {Link} from "gatsby"
import {StaticImage} from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ApplicationHamaphone from "../components/applicationHamaphone";


const linkStyle = {
    textDecoration: "none",
    color: "grey",
    fontWeight: 800,
}

const hrStyle = {
    margin: "50px auto"
}

const leftTdStyle = {
    padding: "10px",
}

const priceLeftTdStyle = {
    padding: "10px",
}

const tableStyle = {
    borderCollapse: "collapse",
    border: "solid 4px #333"
}
const thtdStyle = {
    border: "solid 2px #333"
}

const imgStyle = {
    borderRadius: "5%"
}

const HamaphonePage = () => (
    <Layout>
        <Seo title="はまふぉん"/>

        <div align="center">
            <hr style={hrStyle}/>

            <h1 id="hamaphone">PBXリプレース</h1>

            <div>
                <StaticImage src="../images/products/hamaphone/PBX_logo.png"
                             alt={"wigetaPBX"}
                             style={imgStyle}
                             width={300}/>

            </div>
            <p></p>
            <div>
                <p>カスタマイズであなたの仕事にジャストフィットなPBXを実現。</p>
                    <p>OEM開発商品として販売もできます</p>
            </div>


            <hr style={hrStyle}/>

            <div>
                <h2>PBXリプレースなら</h2>
                <ul>
                    <p>細かいニーズに応えるクラウドPBXへのリプレイスが叶います。</p>
                    <p>システム開発会社直販だからできる高精度なカスタマイズで、電話業務をより快適に、効率的に。</p>

                </ul>
                <StaticImage src="../images/products/hamaphone/wigeta.png"
                             alt={"Screenshot"}
                             style={imgStyle}
                             width={500} />
            </div>
            <hr style={hrStyle}/>

            <div>
                <h3>お見積り</h3>
                <h4>初期費用はカスタマイズ費用のみ</h4>
                <p>機能カスタマイズのご希望を伺ってからお見積りをご提示いたします。</p>
                <p>※カスタマイズ費用例：ウィンドウズ用ソフトフォン作成　100,000円（税込110,000円）</p>
                <hr style={hrStyle}/>
                <h2>料金</h2>
                <h3>基本プラン</h3>
                <p>内線3端末までなら1,900円（税込 2,090円）！</p>
                <p>4端末以降は1端末500円（税込 550円）の低価格で増設できます。</p>
                <table style={tableStyle}>
                    <colgroup style={thtdStyle}>
                        <col width="40%"/>
                        <col width="40%"/>
                        <col width="20%"/>
                    </colgroup>
                    <tr style={thtdStyle}>
                        <td style={leftTdStyle}>１～３内線</td>
                        <td>月額 1,900円</td>
                        <td>（税込 2,090円～）</td>
                    </tr>
                    <tr style={thtdStyle}>
                        <td style={leftTdStyle}>4内線目以降</td>
                        <td>月額  500円/1内線	</td>
                        <td>（税込550円/1内線）</td>
                    </tr>
                </table>
                <h3>オプション</h3>
                <p>下記に記載以外のご要望でもお気軽に問い合わせください。</p>
                <table style={tableStyle}>
                    <colgroup style={thtdStyle}>
                        <col width="40%"/>
                        <col width="40%"/>
                        <col width="20%"/>
                    </colgroup>
                    <tr style={thtdStyle}>
                        <td style={leftTdStyle}>電話番号追加</td>
                        <td style={leftTdStyle}>月額 200円</td>
                        <td style={leftTdStyle}>（税込220円）</td>
                    </tr>
                </table>
                <h3>通話料</h3>
                <p>携帯宛の通話料が他社よりもお得です。</p>
                <p>※NTTひかり電話なら17.6円/1分</p>
                <table style={tableStyle}>
                    <colgroup style={thtdStyle}>
                        <col width="40%"/>
                        <col width="40%"/>
                        <col width="20%"/>
                    </colgroup>
                    <tr style={thtdStyle}>
                        <td style={leftTdStyle}>内線</td>
                        <td>0円</td>
                        <td></td>
                    </tr>
                    <tr style={thtdStyle}>
                        <td style={leftTdStyle}>市内</td>
                        <td>3.8円/1分</td>
                        <td>（税込4.18円/1分）</td>
                    </tr>
                    <tr style={thtdStyle}>
                        <td style={leftTdStyle}>市外</td>
                        <td>3.8円/1分</td>
                        <td>（税込4.18円/1分）</td>
                    </tr>
                    <tr style={thtdStyle}>
                        <td style={leftTdStyle}>携帯</td>
                        <td>14.9円/1分</td>
                        <td>（税込16.39円/1分）</td>
                    </tr>
                </table>
            </div>


            <hr style={hrStyle}/>

            <div>
                <h2>専用ソフトフォン</h2>
                <p>シンプルで使いやすい。日本語対応の安心ソフトフォンです。</p>
                <StaticImage src="../images/products/hamaphone/softphone1.png"
                             alt={"はまふぉんソフトフォン"}
                             style={imgStyle}
                             width={300}/>
                <p>
                    <a href="https://www.ringing.co.jp/download/hamaphone_softphone-3.20.7.exe">◆Download◆</a>
                    <p>※ Windows10/11対応</p>
                </p>
            </div>
            <hr style={hrStyle}/>

            {/*<div>*/}
            {/*    <h2>使い方</h2>*/}
            {/*    <h3>プラン</h3>*/}
            {/*</div>*/}
            <div>
                <p>まずは無料デモをご案内いたします。こちらからお申込みください。</p>
            </div>
            <ApplicationHamaphone/>
        </div>
    </Layout>
)

export default HamaphonePage
