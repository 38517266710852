import * as React from "react"
import { Link } from "gatsby"


const linkStyle = {
    textDecoration: "none",
    color: "grey",
    fontWeight: 800,
    "line-height": 0,
};

const ApplicationHamaphone = () => (
    <div>
        <h3>
            <Link
                to="https://docs.google.com/forms/d/e/1FAIpQLSe8jT6wiG32bSHThfHm5Tz8hDzoVQ2tnGYn2unhAA6Y02k2ag/viewform"
                style={linkStyle}
                rel="noreferrer noopener"
                target="_blank"
            >
                お申し込みフォームへ
            </Link>
        </h3>
    </div>
)

export default ApplicationHamaphone
